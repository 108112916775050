.uxf-wysiwyg {
    /* TODO: (SA-112) narovnat styly! */

    @apply text-base md:text-sm;

    &__toolbar {
        align-items: center;
        background-color: theme("colors.white");
        display: flex;
        margin-bottom: theme("spacing.4");
        position: sticky;
        top: 0;
        z-index: theme("zIndex.fixed");

        &-buttons {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: theme("spacing.2");
        }

        &-button {
            box-shadow: none;
            height: theme("spacing.6");
            margin: 0 theme("spacing.[0.5]");

            &.uxf-button--icon-button {
                width: theme("spacing.6");
            }

            &.uxf-button--variant-default {
                background-color: theme("colors.neutral.100");
                border-radius: theme("borderRadius.md");

                .uxf-icon {
                    color: theme("colors.neutral.600");
                }
            }

            .uxf-wysiwyg__toolbar-button__icon {
                color: theme("colors.neutral.400");
                height: theme("spacing.3");
                width: theme("spacing.4");
            }

            &:hover {
                .uxf-wysiwyg__toolbar-button__icon {
                    color: theme("colors.neutral.600");
                }
            }

            &-undo,
            &-redo {
                .uxf-wysiwyg__toolbar-button__icon {
                    transform: scaleY(-1);
                }
            }

            &-group {
                &:not(:last-of-type) {
                    border-right: 1px solid theme("colors.gray.300");
                    padding-right: theme("spacing.2");

                    .uxf-wysiwyg__toolbar-button {
                        border-radius: theme("borderRadius.md");
                    }
                }

                &-blocks {
                    display: none;
                }
            }
        }
    }

    &__content {
        --color: theme("colors.black");

        color: var(--color);

        :root .dark & {
            --color: theme("colors.white");
        }

        > [data-slate-editor] {
            *:is(h1, h2, h3, h4, h5, h6) {
                margin-bottom: 0.5em;
            }

            > * + * {
                &:is(h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol, div, picture) {
                    margin-top: 1em;
                }

                &:is(li) {
                    margin-top: 0.5em;
                }
            }

            *:is(h1, h2, h3, h4, h5, h6) + *:is(h1, h2, h3, h4, h5, h6) {
                margin-top: 0.5em;
            }
        }
    }

    &__element-with-action-buttons {
        align-items: center;
        border-radius: theme("borderRadius.lg");
        display: flex;
        height: 100%;
        justify-content: center;
        margin-block: theme("spacing.4");
        overflow: hidden;
        position: relative;
        width: 100%;

        &--has-padding {
            padding: theme("spacing.6");
        }

        &--is-selected {
            border: 2px solid theme("colors.primary.500");
        }
    }

    &__element-action-buttons {
        display: flex;
        gap: theme("spacing.1");
        position: absolute;
        right: theme("spacing.3");
        top: theme("spacing.3");
    }

    &__element-button {
        margin-block: theme("spacing.4");
    }

    &__element-highlight-mark {
        --highlight-color: unset;

        color: var(--highlight-color);
    }

    &__element-link {
        --color: theme("colors.blue.600");

        color: var(--color);
        font-weight: theme("fontWeight.medium");

        &:hover {
            text-decoration: underline;
        }

        :root .dark & {
            --color: theme("colors.blue.500");
        }
    }

    &__element-list-ordered {
        list-style: decimal;
        padding-left: theme("spacing.4");
    }

    &__element-list-unordered {
        list-style: disc;
        padding-left: theme("spacing.4");
    }

    &__element-video {
        &__iframe {
            height: theme("height.96");
            pointer-events: none;
            width: 100%;
        }
    }

    &__floating-link-edit {
        &__button {
            color: theme("colors.gray.400");
        }
    }

    &__floating-link-insert {
        --bg-color: theme("colors.white");
        --border-color: theme("colors.neutral.200");

        background-color: var(--bg-color);
        border: 1px solid var(--border-color);
        border-radius: theme("borderRadius.lg");
        box-shadow: theme("boxShadow.DEFAULT");
        overflow: hidden;
        width: theme("width.80");

        :root .dark & {
            --bg-color: theme("colors.gray.900");
            --border-color: theme("colors.neutral.800");
        }

        &__form-inputs {
            @apply space-y-4;

            display: flex;
            flex-direction: column;
            padding: theme("spacing.3");

            .uxf-input__left-addon {
                flex-shrink: 0;
                width: theme("width.28");
            }
        }

        &__form-buttons {
            border-top: 1px solid var(--border-color);
            display: flex;
        }

        &__form-submit-button {
            border-radius: 0;
            flex: 1;
        }
    }

    &__modal {
        padding: theme("spacing.4");

        &-title {
            @apply text-lg;

            font-weight: theme("fontWeight.bold");
            margin-bottom: theme("spacing.3");
            padding-top: theme("spacing.2");
        }

        &-buttons {
            display: flex;
            gap: theme("spacing.2");
            justify-content: flex-end;
            margin-top: theme("spacing.6");
        }
    }

    &__insert-modal-button {
        &__form-inputs {
            @apply space-y-4;
        }
    }

    &__floating-link-insert,
    &__insert-modal {
        .uxf-input {
            border: 1px solid theme("colors.neutral.200");
        }

        .uxf-checkbox {
            border: 1px solid theme("colors.neutral.200");
        }

        .uxf-input__element {
            border-style: none;
            padding: 0;
        }
    }

    .uxf-tooltip {
        background-color: theme("colors.neutral.600");
        border-radius: theme("borderRadius.sm");
        font-size: theme("fontSize.caption");
        padding: theme("spacing.1") theme("spacing.2");

        &__arrow {
            background-color: theme("colors.neutral.600");
            z-index: -1;
        }
    }

    .slate-bold {
        font-weight: bold;
    }

    /* TODO: (SA-112) narovnat styly! END */
}

/* this fixes some portal z-index hell */
[data-floating-ui-portal] > div {
    z-index: 2 !important;
}

@import url("./fonts.css");
@import url("./legacy-reboot.css");

/* global styles */

/* only for testing - highlight elements with attribute "data-testid"
[data-testid] {
    background-color: red !important;
}
*/

body {
    overflow-x: hidden;

    * {
        -ms-overflow-style: -ms-autohiding-scrollbar;
        font-weight: inherit;
        scrollbar-color: rgb(0 0 0 / 25%) transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(0 0 0 / 25%);
            border-radius: 999rem;
            border-style: none;
        }
    }
}

#nprogress {
    pointer-events: none;

    .bar {
        background: #33d2b3;
        height: 4px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10310;
    }

    .peg,
    .spinner {
        display: none;
    }
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;

    #nprogress .spinner,
    #nprogress .bar {
        position: absolute;
    }
}

@media (width <= 47.9375em) {
    #hubspot-messages-iframe-container {
        display: none !important;
        visibility: hidden;
    }
}

/* print overrides */

@media print {
    html,
    body {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .page-container {
        max-width: 100% !important;
    }

    * {
        text-overflow: unset !important;
        white-space: unset !important;

        &[data-noprint] {
            display: none !important;
        }
    }
}

.uxf-button {
    &--variant-default {
        &.uxf-button--color-default {
            :root .light & {
                background-color: theme("colors.primary.500");

                &.is-hoverable:hover,
                &.is-hoverable:active {
                    background-color: theme("colors.primary.600");
                    color: theme("colors.white");
                }
            }
        }
    }
}

/* DO NOT DELETE! (needed for proper working of current styled-components based ui kit */

:root {
    --system-fonts: verdana, system-ui, -apple-system, segoe ui, roboto, ubuntu, cantarell, noto sans, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --system-fonts-monospace: sfmono-regular, menlo, monaco, consolas, roboto mono, ubuntu monospace, noto mono,
        oxygen mono, liberation mono, monospace;
    --default-font-family: work-sans-custom, work-sans-fallback;
    --default-font-family-monospace: monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    cursor: default;
    font-family: sans-serif;
    line-height: 1.15;
    text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

@viewport {
    width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    background-color: #fff;
    color: #3c3c3b;
    font-family: var(--default-font-family), var(--system-fonts);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

abbr[title],
abbr[data-original-title] {
    border-bottom: 0;
    cursor: help;
    text-decoration: underline dotted;
    text-decoration: underline;
}

address {
    font-style: normal;
    line-height: inherit;
    margin: 0;
}

ol,
ul,
dl {
    margin: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin: 0;
}

dt {
    font-weight: 400;
}

dd {
    margin: 0;
}

blockquote {
    margin: 0;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    background-color: transparent;
    color: inherit;
    text-decoration: inherit;
    text-decoration-skip: objects;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

pre,
code,
kbd,
samp {
    font-family: var(--default-font-family-monospace), var(--default-font-family-monospace);
    font-size: 1em;
}

pre {
    margin: 0;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0;
}

img {
    border-style: none;
    vertical-align: middle;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    caption-side: bottom;
    color: inherit;
    padding: 0;
    text-align: left;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin: 0;
}

button {
    border-radius: 0;

    &:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }
}

input,
button,
select,
optgroup,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

legend {
    color: inherit;
    display: block;
    font-size: 1em;
    line-height: inherit;
    margin: 0;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    appearance: none;
    outline-offset: -2px;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        appearance: none;
    }
}

::-webkit-file-upload-button {
    appearance: button;
    font: inherit;
}

output {
    display: inline-block;
}

summary {
    cursor: pointer;
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

/* TODO: migrate to @next/font */

@font-face {
    font-display: fallback;
    font-family: work-sans-custom;
    font-style: normal;
    font-weight: 400;
    src:
        local("Work Sans"),
        local("WorkSans-Regular"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-regular.woff2") format("woff2"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-regular.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: work-sans-custom;
    font-style: normal;
    font-weight: 500;
    src:
        local("Work Sans Medium"),
        local("WorkSans-Medium"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-500.woff2") format("woff2"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-500.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: work-sans-custom;
    font-style: normal;
    font-weight: 600;
    src:
        local("Work Sans SemiBold"),
        local("WorkSans-SemiBold"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-600.woff2") format("woff2"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-600.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: work-sans-custom;
    font-style: normal;
    font-weight: 700;
    src:
        local("Work Sans Bold"),
        local("WorkSans-Bold"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-700.woff2") format("woff2"),
        url("/static/fonts/work-sans/work-sans-v5-latin_latin-ext-700.woff") format("woff");
}

@font-face {
    font-family: work-sans-fallback;
    size-adjust: 111.69%;
    src: local("Arial");
}

@font-face {
    font-family: work-sans-fallback;
    font-weight: 500;
    size-adjust: 110%;
    src: local("Arial");
}

@font-face {
    font-family: work-sans-fallback;
    font-weight: 600;
    size-adjust: 102%;
    src: local("Arial Bold");
}

@font-face {
    font-family: work-sans-fallback;
    font-weight: 800;
    size-adjust: 102%;
    src: local("Arial Extra Bold");
}
